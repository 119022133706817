import React from 'react'
/*import { Link } from 'gatsby'*/

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
    <Layout>
        <SEO title="" keywords={[]} />
        <h1><emph>Hi,</emph> I'm Dominik</h1>
        <section>
            <p>I develop digital products.<br/>You can contact me <a href="mailto:contact@seemann.rocks">via email</a> or on <a href="https://www.linkedin.com/in/dominik-seemann/">linkedin</a>.</p>
        </section>
    </Layout>
)

export default IndexPage
